/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import "~@ionic/angular/css/display.css";

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

/* 기본 리셋 reset*/
*,
html,
body {
  // font-size: 16px; // rem 단위 폰트 지정
  // color: unset;
  // font-weight: normal;
  line-height: unset;
  letter-spacing: unset;
}

/* You can add global styles to this file, and also import other style files */
/*reset*/
html,
body,
div,
span,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
address,
em,
img,
ins,
small,
strong,
sub,
sup,
var,
b,
i,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section,
summary,
time,
audio,
video {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  vertical-align: baseline;
  background: transparent;
  box-sizing: border-box;
}

body {
  line-height: 1;
}
article,
aside,
details,
figcaption,
figure,
main,
footer,
header,
hgroup,
menu,
nav,
section {
  box-sizing: border-box;
  display: block;
}
ul,
li,
dl,
dd,
dt {
  box-sizing: border-box;
  list-style: none;
}
a {
  margin: 0;
  padding: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
  text-decoration: none;
  box-sizing: border-box;
}
input,
select {
  vertical-align: middle;
}

img {
  border-style: none;
  display: block;
  width: 100%;
  height: 100%;
}
address {
  font-style: normal;
}
button,
input,
textarea,
select,
a {
  border: 0;
  outline: none;
  resize: none;
  box-sizing: border-box;
}

input,
select,
button {
  vertical-align: middle;
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  border-radius: 0;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
}
/** Correct the inability to style clickable types in iOS and Safari. */
button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}
/** * Remove the inner border and padding in Firefox. */
button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}
/** * Restore the focus styles unset by the previous rule. */
button:-moz-focusring,
[type="button"]:-moz-focusring,
[type="reset"]:-moz-focusring,
[type="submit"]:-moz-focusring {
  outline: 1px dotted ButtonText;
}
/** * 1. Add the correct box sizing in IE 10. * 2. Remove the padding in IE 10. */
[type="checkbox"],
[type="radio"] {
  box-sizing: border-box; /* 1 */
  padding: 0; /* 2 */
}
/** * Correct the cursor style of increment and decrement buttons in Chrome. */
[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto;
}
/** * 1. Correct the odd appearance in Chrome and Safari.
 * 2. Correct the outline style in Safari. */
[type="search"] {
  -webkit-appearance: textfield; /* 1 */
  outline-offset: -2px; /* 2 */
}
/** * Remove the inner padding in Chrome and Safari on macOS. */

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}
/** * 1. Correct the inability to style clickable types in iOS and Safari.
* 2. Change font properties to `inherit` in Safari. */
::-webkit-file-upload-button {
  -webkit-appearance: button; /* 1 */
  font: inherit; /* 2 */
}

button {
  cursor: pointer;
}

/*reset */

/********** ionic reset ********/
ion-header,
ion-footer {
  --min-height: 56px;
  --background: #fff;
}

ion-toolbar {
  --padding-top: 0;
  --padding-bottom: 0;
  --padding-start: 0;
  --padding-end: 0;
  --min-height: 56px;
  --background: #fff;
}

ion-tab-bar {
  min-height: 56px;
  --background: #fff;
  --border: 0;
}

.header-ios ion-toolbar:last-of-type {
  --border-width: 0 0 0;
}

.footer-ios ion-toolbar:first-of-type {
  --border-width: 0;
}

ion-label {
  margin: 0;
}

ion-list-header {
  padding: 0;
  min-height: auto;
  align-items: center;
}

ion-card {
  margin: 0;
  border-radius: 0;
  box-shadow: none;
}

ion-card-header {
  padding: 0;
}
ion-card-subtitle {
  margin: 0;
}
ion-card-title {
  font-weight: normal;
}

ion-card-content.card-content-ios {
  padding: 0;
}

ion-segment {
  --background: #fff;
  border-radius: 0;
}

ion-segment-button {
  margin: 0;
  --border-radius: 0;
  --border-width: 0;
  --indicator-box-shadow: none;
  min-height: unset;
}

ion-item {
  --border-width: 0;
  --inner-border-width: 0;
  --min-height: unset;
  --padding-start: 0;
  --inner-padding-end: 0;
  --background-activated: none;
  --background-focused: none;
  --background-hover: none;
  --background-activated-opacity: 0;
  --background-focused-opacity: 0;
  --background-hover-opacity: 0;
  &::part(native) {
    padding: 0;
  }
}

ion-badge {
  padding: 0;
}

ion-button {
  margin: 0;
  height: auto;
  --padding-start: 0;
  --padding-end: 0;
  --background: transparent;
  --background-activated: none;
  --background-focused: none;
  --background-hover: none;
  &[disabled] {
    opacity: 1;
  }
}

ion-item-divider {
  --background: unset;
  min-height: auto;
  --inner-padding-end: 0;
  --padding-start: 0;
}

ion-grid {
  padding: 0;
}

ion-col {
  padding: 0;
}

ion-accordion {
  ion-item {
    --background-activated: #fff;
  }
}

ion-radio {
  margin: 0;
}

ion-checkbox {
  margin: 0;
}

ion-select {
  --padding-top: 0;
  --padding-end: 0;
  --padding-bottom: 0;
  --padding-start: 0;
  --placeholder-opacity: 1;
}

ion-avatar {
  --border-radius: 50%;
  border-radius: 50%;
  overflow: hidden;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.sc-ion-input-ios-h,
.sc-ion-textarea-ios-h {
  --padding-top: 0;
  --padding-end: 0;
  --padding-bottom: 0;
  --padding-start: 0;
  --placeholder-color: 1;
}

.sc-ion-searchbar-ios-h {
  padding: 0;
  padding-inline-start: 0;
  padding-inline-end: 0;
}

/********** ionic reset ********/

////////그외 코드 참고사항

//######### modal css #########
//가로 세로 100%
.modal-wrapper.sc-ion-modal-ios {
  width: 100%;
  height: 100vh;
  border-radius: 0;
}

//######### alert css #########
ion-alert.sc-ion-alert-ios-h {
  --max-width: 280px;
  --width: 100%;
  --backdrop-opacity: 0.6;
}

//######### @mixin css #########
//font style
@mixin font($color: red, $weight, $size, $line) {
  color: $color;
  font: {
    size: $size;
    weight: $weight;
    line-height: $line;
  }
}

//말줄임 1줄
.row-ellipsis {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
//말줄인 2줄
.rows-ellipsis {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-all;
  white-space: pre-line;
}

.list-none {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  p {
    text-align: center;
  }
}

.show-back-button {
  width: 44px;
  height: 44px;
  // background: url(./assets/icon/favicon.png) no-repeat; //url
  background-size: 24px;
  background-position: center;
}

// **************************************************************** custom ****************************************************************

* {
  font-weight: 400;
  font-size: inherit;
  color: #161616;
}
ion-content {
  --padding-top: 1rem;
  --padding-start: 1.5rem;
  --padding-bottom: 1rem;
  --padding-end: 1.5rem;
  --background: url(./assets/imgs/bg.png) no-repeat center / 100% 100%;
}
.d-flex {
  display: flex;
  align-items: center;
  width: 100%;
  &.center {
    justify-content: center;
  }
  &.end {
    justify-content: flex-end;
  }
  &.jcsb {
    justify-content: space-between;
  }
  &.col {
    flex-direction: column;
  }
}

// 공통 헤더
.basic-header {
  ion-toolbar {
    --min-height: 0;
    --background: transparent;
  }
  .header-inner {
    & > div {
      gap: 0.375rem;
      width: auto;
      ion-button {
        min-width: 5.419vw;
        width: 5.419vw;
        height: 5.419vw;
        background: no-repeat center / 100% 100%;
      }
    }
    .return-btn {
      background-image: url(./assets/imgs/icon-return-on.png);
      &[disabled] {
        background-image: url(./assets/imgs/icon-return.png);
      }
    }
    .turn-btn {
      background-image: url(./assets/imgs/icon-turn-on.png);
      &[disabled] {
        background-image: url(./assets/imgs/icon-turn.png);
      }
    }
    .save-btn {
      background-image: url(./assets/imgs/icon-save.png);
    }
    .listen-btn {
      background-image: url(./assets/imgs/icon-listen.png);
    }
  }
}

// 공통 내용
.basic-container {
  min-height: calc(100% - 1rem - 1rem - 1.5rem - var(--ion-safe-area-top, 0));
  padding-left: var(--ion-safe-area-start, 0);

  /* Status bar height on iOS 11.0 */
  padding-right: 20px;
  /* Status bar height on iOS 11.0 */
  padding-right: constant(safe-area-inset-right);
  /* Status bar height on iOS 11+ */
  padding-right: env(safe-area-inset-right);

  // 악보
  .music-note-wrap {
    gap: 3vw;
    margin: 3.69vw 0 2vw;
    ion-button {
      // max-width: 6.433vw;
      // width: 6.433vw;
      // height: 5.419vw;
      background: no-repeat center / 100% 100%;
      // min-width: 2.75rem;
      // max-width: 5.419vw;

      min-width: 2.75rem;
      min-height: 2.75rem;
      width: 2.75rem;
      height: 2.75rem;
      max-width: 2.75rem;
      max-height: 2.75rem;
      &.con-prev-btn {
        background-image: url(./assets/imgs/icon-prev-on.png);
        &[disabled] {
          background-image: url(./assets/imgs/icon-prev.png);
        }
      }
      &.con-next-btn {
        background-image: url(./assets/imgs/icon-next.png);
      }
    }
    // 악보
    .note-view-wrap {
      position: relative;
      width: calc(100% - env(safe-area-inset-right));
      &.line,
      &.last {
        max-width: 71.2vw;

        .pice {
          &.pice-4 {
            min-width: auto;
            width: 99%;
            .border-box {
              min-width: 32.4vw;
            }
          }
        }

        .num-wrap {
          position: absolute;
          top: -0.5vw;
          left: 2.5vw;
          width: calc(100% - 2.5vw);
        }
      }

      //
      .note-inner {
        position: relative;
        padding-bottom: 0.2vw;
        max-width: 100%;
        // max-width: 77.956vw;
        width: 100%;
        min-height: 9.114vw;
        // // inner = 1마디씩
        // 1,2 마디, 노트 1번째줄
        &.first {
          padding-left: 5.5vw;
          background: url(./assets/imgs/music-note-4-4.png) no-repeat center /
            100% 100%;
          .left {
            padding-left: 1.5vw;
          }
        }
        // 3,4 마디
        &.line {
          padding-left: 1vw;
          background: url(./assets/imgs/music-note-line.png) no-repeat center /
            100%;
        }
        // 5,6 마디
        &.note {
          padding-left: 5.5vw;
          background: url(./assets/imgs/music-note.png) no-repeat center / 100%
            100%;
          .left {
            padding-left: 1.5vw;
          }
        }
        // 7,8 마디, 노트 2번째줄
        &.last {
          // padding-left: 3.8vw;
          background: url(./assets/imgs/music-note-last.png) no-repeat center /
            100%;
          .left {
            padding-right: 5.8vw;
          }
          .inner {
            &.padding-layout {
              min-width: 27.7%;
              .pice {
                &.pice-0-5 {
                  width: calc(
                    2.587vw - (env(safe-area-inset-right) / 16)
                  ) !important;
                }
                &.pice-1 {
                  width: calc(
                    (2.587vw - (env(safe-area-inset-right)) / 16) * 2
                  ) !important;
                }
                &.pice-2 {
                  width: calc(
                    (2.587vw - (env(safe-area-inset-right)) / 16) * 4
                  ) !important;
                }
                &.pice-3 {
                  width: calc(
                    (2.587vw - (env(safe-area-inset-right)) / 16) * 6
                  ) !important;
                }
                &.pice-4 {
                  width: calc(
                    (2.587vw - (env(safe-area-inset-right)) / 16) * 8
                  ) !important;
                }
              }
            }
          }
          // 2번째줄(5~8) 화성
          .part-wrap {
            padding-left: 4vw;
          }
        }
      }
      // = 110
      &::before {
        display: block;
        content: "";
        position: absolute;
        left: 0;
        top: -1.375rem;
        z-index: 1;
        width: 4.1875rem;
        height: 1rem;
        background: url(./assets/imgs/icon-part.png) no-repeat center / 100%
          100%;
      }
      // 마디 넘버
      .num-wrap {
        position: absolute;
        top: -0.5vw;
        left: 8.5vw;
        width: calc(100% - 8.5vw);
        // justify-content: space-between;
        span {
          display: inline-block;
          width: 50%;
          font-size: 0.6rem;
          line-height: 0.75rem;
          text-align: right;
          &.left-num {
            // padding-right: 0.875rem;
            padding-right: 0.875rem;
          }
          &.right-num {
            padding-right: 0.25rem;
          }
        }
      }

      // 화성 영역
      .part-wrap {
        position: absolute;
        bottom: -3.6vw;
        left: -0.2vw;
        padding-left: 6.6vw;
        .part-area {
          min-width: 25%;
          .part {
            min-width: 2.217vw;
            img {
              width: 2.217vw;
              max-width: 2.217vw;
              height: 2.094vw;
            }
          }
        }
      }

      .last {
        // 화성 영역
        .part-wrap {
          position: absolute;
          bottom: -3.6vw;
          left: -0.2vw;
          padding-left: 6.6vw;
          .part-area {
            &:first-child {
              min-width: 28%;
            }
            min-width: 23.5%;
            .part {
              min-width: 2.217vw;
              img {
                width: 2.217vw;
                max-width: 2.217vw;
                height: 2.094vw;
              }
            }
          }
        }
      }

      // left, right
      .left,
      .right {
        width: 100%;
      }
      // 박자
      .pice {
        position: relative;
        min-height: 6.9vw;
        // 박자영역
        .border-box {
          height: 6.9vw;
          border: 2px solid #ff7bab;
          border-radius: 4px;
          background: rgba(255, 123, 171, 0.3);
        }

        .border-rest {
          height: 6.9vw;
          border: 2px solid #ff7bab;
          border-radius: 4px;
          background: transparent;
        }
        // 반박자
        &.pice-0-5 {
          min-width: calc(4.188vw - (env(safe-area-inset-right) / 16));
          .border-box {
            min-width: calc(4.188vw - (env(safe-area-inset-right) / 16));
          }
        }
        // 1박자
        &.pice-1 {
          min-width: calc((4.188vw - (env(safe-area-inset-right) / 16)) * 2);
          .border-box,
          .border-rest {
            min-width: calc((4.188vw - (env(safe-area-inset-right) / 16)) * 2);
          }
        }
        // 2박자
        &.pice-2 {
          min-width: calc((4.188vw - (env(safe-area-inset-right) / 16)) * 4);
          .border-box {
            min-width: calc((4.188vw - (env(safe-area-inset-right) / 16)) * 4);
          }
        }
        // 3박자
        &.pice-3 {
          min-width: calc((4.188vw - (env(safe-area-inset-right) / 16)) * 6);
          .border-box {
            min-width: calc((4.188vw - (env(safe-area-inset-right) / 16)) * 6);
          }
        }
        // 4박자
        &.pice-4 {
          //min-width: 35.828vw;
          min-width: calc((4.188vw - (env(safe-area-inset-right) / 16)) * 8);
          .border-box {
            min-width: calc((4.188vw - (env(safe-area-inset-right) / 16)) * 8);
            //min-width: 35.828vw;
          }
        }
        // 쉼표인 경우
        &.sim {
          min-width: calc((4.188vw - (env(safe-area-inset-right) / 16)) * 2);
          .border-box {
            min-width: calc((4.188vw - (env(safe-area-inset-right) / 16)) * 2);
            background: transparent;
          }
          .area {
            bottom: 0;
            top: 50%;
            // left: 50%;
            left: 0;
            transform: translateY(-50%);
          }
        }
      }
      // 음표영역
      .area {
        position: absolute;
        min-width: 2vw;
        width: 2vw;
        height: 5.05vw;
        z-index: 2;
        // 가온 도 위치
        &._0 {
          bottom: 0vw;
        }
        // 레 위치
        &._1 {
          bottom: -0.25vw;
        }
        // 미 위치
        &._2 {
          bottom: 0.3vw;
        }
        // 파 위치
        &._3 {
          bottom: 0.98vw;
        }
        // 솔 위치
        &._4 {
          bottom: 1.55vw;
        }
        // 라 위치
        &._5 {
          bottom: 2.22vw;
        }
        // 시 위치
        &._6 {
          bottom: -0.17vw;
          transform: rotate(180deg);
        }
        // 높은 도 위치
        &._7 {
          bottom: 0.35vw;
          transform: rotate(180deg);
        }
        &.rotate-cancel {
          transform: rotate(0deg) !important;
        }
      }
    }
  }

  // 피아노 버튼
  .music-btn-wrap {
    // music-inner, note-inner
    & > div {
      gap: 0.5rem;
      ion-button {
        background: no-repeat center / 100% 100%;
      }
    }
    // 음표버튼
    .music-inner {
      margin-bottom: 0.5rem;
      ion-button {
        width: 8.4vw;
        height: 9.24vw;
      }
      ._1 {
        background-image: url(./assets/imgs/music-1-on.png);
        &[disabled] {
          background-image: url(./assets/imgs/music-1.png);
        }
      }
      ._2 {
        background-image: url(./assets/imgs/music-2-on.png);
        &[disabled] {
          background-image: url(./assets/imgs/music-2.png);
        }
      }
      ._3 {
        background-image: url(./assets/imgs/music-3-on.png);
        &[disabled] {
          background-image: url(./assets/imgs/music-3.png);
        }
      }
      ._4 {
        background-image: url(./assets/imgs/music-4-on.png);
        &[disabled] {
          background-image: url(./assets/imgs/music-4.png);
        }
      }
      ._5 {
        background-image: url(./assets/imgs/music-5-on.png);
        &[disabled] {
          background-image: url(./assets/imgs/music-5.png);
        }
      }
      ._6 {
        background-image: url(./assets/imgs/music-6-on.png);
        &[disabled] {
          background-image: url(./assets/imgs/music-6.png);
        }
      }
    }

    // 건반버튼
    .note-inner {
      ion-button {
        width: 8.7vw;
        height: 11vw;
      }
      // 가온 도
      ._0 {
        background-image: url(./assets/imgs/note-1-on.png);
        &[disabled] {
          background-image: url(./assets/imgs/note-1.png);
        }
      }
      // 레
      ._1 {
        background-image: url(./assets/imgs/note-2-on.png);
        &[disabled] {
          background-image: url(./assets/imgs/note-2.png);
        }
      }
      // 미
      ._2 {
        background-image: url(./assets/imgs/note-3-on.png);
        &[disabled] {
          background-image: url(./assets/imgs/note-3.png);
        }
      }
      // 파
      ._3 {
        background-image: url(./assets/imgs/note-4-on.png);
        &[disabled] {
          background-image: url(./assets/imgs/note-4.png);
        }
      }
      // 솔
      ._4 {
        background-image: url(./assets/imgs/note-5-on.png);
        &[disabled] {
          background-image: url(./assets/imgs/note-5.png);
        }
      }
      // 라
      ._5 {
        background-image: url(./assets/imgs/note-6-on.png);
        &[disabled] {
          background-image: url(./assets/imgs/note-6.png);
        }
      }
      // 시
      ._6 {
        background-image: url(./assets/imgs/note-7-on.png);
        &[disabled] {
          background-image: url(./assets/imgs/note-7.png);
        }
      }
      // 높은 도
      ._7 {
        background-image: url(./assets/imgs/note-8-on.png);
        &[disabled] {
          background-image: url(./assets/imgs/note-8.png);
        }
      }
    }
  }
}

// alert
.sc-ion-alert-ios-h {
  .alert-message {
    padding: 0.6875rem 1rem 1.75rem;
    font-size: 1rem;
    line-height: 1.5rem;
  }
  .alert-button {
    border-top: 1px solid #f0f0f0;
    span {
      font-weight: 600;
      font-size: 1.125rem;
      line-height: 1.875rem;
      color: #ff70ac;
    }
  }
}

// toast
ion-toast {
  // --background: rgba(22, 22, 22, 0.6);
  --max-width: 251px;
  --height: 28px;
  font-weight: 500;
  font-size: 0.9375rem;
  line-height: 1.25rem;
  color: #ffffff;
  text-align: center;
  --border-radius: 6px;
  --background: #575757;
  transform: translateY(-10px);
}

// loading
ion-loading.sc-ion-loading-ios-h {
  --max-width: 31.25rem;
  --width: 100%;
  --spinner-color: #ff659d;
  --background: transparent;
  --backdrop-opacity: 0;
  .loading-wrapper {
    flex-direction: column;
    gap: 2.125rem;
  }
  .loading-content {
    font-size: 1rem;
    line-height: 1.875rem;
  }
}

// 타블렛 가로 iPadPro:1366 부터
@media (orientation: landscape) and (min-width: 1300px) {
  .basic-container {
    .music-note-wrap {
      .note-view-wrap {
        // 음표영역
        .area {
          &._0 {
            bottom: -0.6vw;
          }
        }
      }
    }
  }
}

// 타블렛 가로 iPadMini:1024 부터
@media (orientation: landscape) and (min-width: 1000px) {
  .basic-container {
    min-height: calc(100% - 16px - 32px - 24px - var(--ion-safe-area-top, 0));
    .music-note-wrap {
      .note-view-wrap {
        // 마디 넘버
        .num-wrap {
          top: 0;
        }
      }
    }
  }
}

// 모바일 가로 iPhoneX:812 전까지
@media (orientation: landscape) and (max-width: 900px) and (min-width: 813px) {
  .basic-container {
    .music-note-wrap {
      .note-view-wrap {
        &.first {
          // padding-left: 6.7vw;
        }
        &.note {
          // padding-left: 6.4vw;
        }

        // 음표영역
        .area {
          // 가온도 위치
          &._0 {
            bottom: -0.6vw;
          }
        }
      }
    }
  }
}

// 모바일 가로 갤럭시S8+ 아래까지
@media (orientation: landscape) and (max-width: 800px) {
  .basic-container {
    min-height: calc(100% - 16px - 32px - 24px - var(--ion-safe-area-top, 0));
    .music-note-wrap {
      .note-view-wrap {
        .num-wrap {
          top: 0;
        }
        &.first {
          // padding-left: 6.7vw;
          padding-left: 0;
        }
        &.note {
          // padding-left: 6.4vw;
          padding-left: 0;
        }
        // .note-inner.last {
        //   // .part-wrap {
        //   //   .part-area:first-of-type {
        //   //     min-width: 20.6vw;
        //   //   }
        //   // }
        // }
      }
    }
  }
}

@media screen and (max-width: 700px) {
  html,
  body {
    font-size: 15px;
  }
}
@media screen and (max-width: 600px) {
  html,
  body {
    font-size: 14px;
  }
}
